<template>
    <div class="main_r_info_box">
        <h3 class="main_r_info_box_title">{{menuData.categoryTitle}}</h3>
        <div class="main_r_info_box_title_p">
            <img :src="defaultImg1" alt="">
            <p><span>项目介绍：</span>{{menuData.categoryDescrip}}</p>
        </div>
        <div v-for="(sitem,key) in articleList" :key="key">
            <h4 v-if="sitem.docs && sitem.docs.length > 0" class="main_r_info_box_title2"><span>{{key}}</span></h4>
            <ul v-if="sitem.docs && sitem.docs.length > 0" class="main_r_info_box_ul">
                <li v-if="sitem.catagoryType == 1" v-for="(item,i) in sitem.docs" :key="i">
                    <a :href="item.contentIsChain == '1' ? item.contentChain : '/detail?docId='+item.id" :title="item.contentTitle" target="_blank" style="display:block;">
                        <h4 class="main_r_info_box_li_h4"><span style="display: inline-block;font-size: 22px;">· </span>{{item.contentTitle ? item.contentTitle : ''}}</h4>
                    </a>
                </li>
                <li v-if="sitem.catagoryType == 2" v-for="(item,i) in sitem.docs" :key="i">
                    <h4 class="main_r_info_box_li_h4" style="width:100%;">{{item.contentTitle ? item.contentTitle : ''}}</h4>
                   <p v-html="item.contentDetails" style="width: 100%;font-size: 16px;font-weight: 400;color: #333333;line-height: 29px;text-align: left;margin-top:40px;"></p>
                </li>
            </ul>
        </div>
        
        <!-- <div v-if="articleList.length > 0" class="page_box">
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="pageNum"
                :page-size="pageSize"
                layout="prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </div> -->
        <el-empty v-if="!ifEmpty" description="暂无数据!"></el-empty>
    </div>
</template>

<script>

import $ from 'jquery';
import api from "@/../static/api/api.js";

import defaultImg1 from '@/assets/imgs/default.png'
import defaultImg2 from '@/assets/imgs/default2.png'
import defaultImg3 from '@/assets/imgs/default3.png'

export default {
    name: 'kangyiInfo',
    props:['currentId'],
    data() {
        return {
            imgBase: this.$store.state.imgBase,
            defaultImg1: defaultImg1,//轮播图默认图片
            defaultImg2: defaultImg2,//研究项目默认图
            defaultImg3: defaultImg3,//交流传播默认图
            articleList: [],//右侧展示文章列表数据
            pageNum: 1,//分页的三个值
            pageSize: 15,
            total: 0,
            curtitle: '',
            menuData: [],//获取栏目数据
            ifEmpty: false,//是否内容为空
        }
    },
    watch: {
        currentId (val) {
            this.rightInfoFn(val);
            this.fetchMenuData(val);
        }
    },
    mounted() {
        this.fetchMenuData(this.currentId);//获取栏目数据
        this.rightInfoFn(this.currentId);//默认展示CIKD大讲堂
    },
    methods: {
        fetchMenuData(currentId){
            let _this = this;

            let menuId1 = currentId;
            let onlyChild1 = true;
            let paltformId1 = '';
            api.getUnderMenuList(menuId1, onlyChild1, paltformId1).then(res => {
                if (res.data) {
                    _this.menuData = res.data; 
                    _this.curtitle = res.data.categoryTitle;
                    
                }
            });
        },
        //加载右侧文章列表数据
        rightInfoFn(index, curNum=1){
            let _this = this;
            let menuId = index;
            let pageNum = curNum;
            let pageSize =1000;
            let isKY = true;
            _this.ifEmpty = false;
            api.getArticleList(menuId, pageNum, pageSize, isKY).then(res => {
                if (res.data) {
                    _this.articleList = res.data.map;
                    _this.pageNum = res.data.pageNum;
                    _this.pageSize =res.data.pageSize;
                    _this.total =res.data.total;
                    // console.log(res.data.map[0],'res.data.list');
                    // console.log(res.data.title,'res.data');
                    // _this.$emit('titleInfo',res.data.title);//将当前所属栏目传给父组件
                    // _this.curtitle = res.data.title;
                    _this.$emit('titleInfo',res.data.categoryPosition);//将当前所属栏目传给父组件
                    for(let item in _this.articleList){
                        _this.articleList[item].docs.length > 0 ? _this.ifEmpty = true : '';
                    }
                }
            });
        },
        //分页操作函数1
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
        },
        //分页操作函数2
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.rightInfoFn(this.currentId, val);
        }
    }
}
</script>

<style scoped>

.main_r_info_box{
    width:100%;
    margin-top:38px;
}
.main_r_info_box_title{
    width: 100%;
    height: 22px;
    font-size: 22px;
    /* font-family: STXihei; */
    font-weight: bold;
    color: #333333;
    line-height: 22px;
    text-align: center;
    margin-bottom: 50px;
}
.main_r_info_box_title_p{
    width:100%;
    overflow: hidden;
}
.main_r_info_box_title_p img{
    float: left;
    width: 238px;
    /* height: 174px; */
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
}
.main_r_info_box_title_p p{
    float: right;
    width: 600px;
    /* height: 124px; */
    font-size: 16px;
    /* font-family: STXihei; */
    color: #1A1A1A;
    line-height: 36px;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.main_r_info_box_title_p p span{
    font-weight: bold;
}
.main_r_info_box_title2{
    width:100%;
    border-bottom: 1px solid #E5E5E5;
    text-align: left;
    margin-top:50px;
    margin-bottom: 35px;
}
.main_r_info_box_title2 span{
    width: 37px;
    height: 38px;
    font-size: 18px;
    /* font-family: STXihei; */
    font-weight: bold;
    color: #333333;
    line-height: 38px;
    border-bottom:2px solid #1B70CB;
    display: block;
}


.main_r_info_box_ul{
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin-bottom:130px;
}
.main_r_info_box_ul li{
    /* width: 99%;
    height:180px;
    overflow: hidden;
    float: left;
    border: 1px solid #E3E3E3; */
    margin-bottom: 14px;
    padding-bottom: 14px;
    margin-left: 13px;
    overflow: hidden;
}
/* .main_r_info_box_ul li:hover{
    box-shadow: 0px 5px 5px 0px rgba(223, 226, 230, 0.75);
} */
.main_r_info_box_ul li a{
    width:100%;
    overflow: hidden;
}

.main_r_info_box_li_time{
    width: 77px;
    height: 15px;
    font-size: 15px;
    font-family: Arial;
    font-weight: 400;
    color: #999999;
    line-height: 15px;
    float: left;
    margin-left: 28px;
    text-align: right;
    /* margin-top:27px; */
    float:right;
}
.main_r_info_box_li_h4{
    width: 721px;
    /* height: 18px; */
    /* font-family: STXihei; */
    font-weight: 400;
    line-height: 18px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    float: left;
    text-align: left;
    /* margin-bottom:31px; */
    font-size: 16px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #4D4D4D;
}
.main_r_info_box_li_h4:hover{
    color:#1D86C7;
}

/* 分页 */
.page_box{
    width:100%;
    height:63px;
    margin-top:30px;
    margin-bottom:100px;
}





</style>
